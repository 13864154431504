import React from "react";
import { Checkbox, Radio } from "@material-ui/core";
import DatePicker from "react-date-picker";
import { withStyles } from "@material-ui/core/styles";
import { ShipperSelection } from "..";
import moment from "moment";
import { isMaxTrustUser } from "@utils";

const style = () => ({
  seperator: {
    paddingBottom: "13px",
    borderBottom: "1px solid #c7c5c74f",
    marginBottom: "6px",
  },
  "@global": {
    "table#extraKtvTable": {
      borderSpacing: 0,
      fontSize: 12,
      width: "100%",
    },
    "#extraKtvTable thead > tr": {
      background: "#1d2480a3",
    },
    "#extraKtvTable thead > tr > td": {
      color: "white",
      borderBottom: "1px solid #ffffff6e",
      borderRight: "1px solid #ffffff6e",
    },
    "#extraKtvTable tbody > tr:nth-child(odd)": {
      background: "#d6e3f752",
    },
    "#extraKtvTable tbody > tr > td:first-child": {
      borderLeft: "1px solid #0000000d",
    },
    "#extraKtvTable tbody > tr > td:last-child": {
      borderRight: "1px solid #0000000d",
    },
    ".createTransportDatePicker .react-date-picker__calendar": {
      right: 0,
      left: "auto",
    },
    ".customDatePicker .react-date-picker__wrapper": {
      height: 39,
      border: "1px solid #ccc",
      borderRadius: 4,
    },
    ".react-date-picker__inputGroup__input.react-date-picker__inputGroup__year":
      {
        minWidth: "30px",
      },
  },
});
class ShipperExtraSelection extends React.Component {
  handleChange = (type, e, ele) => {
    const { changeExtraShipper, shipperExtra } = this.props;
    let list = [...shipperExtra];
    const hasMainKtv = shipperExtra.findIndex((x) => x.isMainKtv === true);
    const foundIdx = ele ? list.findIndex((x) => x.id === ele.id) : -1;
    if (type === "shipper") {
      if (e) {
        const selectedShipper = {
          ...e,
          id: e.value,
          timekeeping_type: 0,
          isMainKtv: hasMainKtv !== -1 ? (ele ? ele.isMainKtv : false) : true,
          timekeeping_date: ele
            ? ele.timekeeping_date
            : moment().format("YYYY-MM-DD"),
        };
        if (ele) {
          list[foundIdx] = selectedShipper;
        } else {
          list = [...list, selectedShipper];
        }
      } else {
        if (ele) {
          if (list[foundIdx].isMainKtv) {
            list.splice(foundIdx, 1);
            if (list.length > 0) list[0].isMainKtv = true;
          } else list.splice(foundIdx, 1);
        }
      }
    } else if (type === "isMainKtv") {
      const mainKtvIdx = shipperExtra.findIndex((x) => x.isMainKtv === true);
      if (mainKtvIdx !== -1 && list[mainKtvIdx].id !== ele.id) {
        list[mainKtvIdx].isMainKtv = false;
        list[foundIdx].isMainKtv = true;
      }
    } else if (["isCheckin", "isCheckout"].includes(type)) {
      const prevType = ele.timekeeping_type;
      if (type === "isCheckin") {
        list[foundIdx].timekeeping_type =
          prevType === 1 ? 0 : prevType === 2 ? 3 : prevType === 3 ? 2 : 1;
      } else if (type === "isCheckout") {
        list[foundIdx].timekeeping_type =
          prevType === 1 ? 3 : prevType === 2 ? 0 : prevType === 3 ? 1 : 2;
      }
    } else if (type === "date") {
      list[foundIdx].timekeeping_date = e;
    }
    changeExtraShipper(list);
  };

  renderExtraKtv = (isMaxTrust) => {
    const { ktvList, shipperExtra } = this.props;
    if (shipperExtra.length > 0) {
      let dataTemplate = shipperExtra.map((ele, id) => {
        const shipperExtraIds = shipperExtra
          .filter((x) => x.id !== ele.id)
          .map((x) => x.id);
        let ktvsExtra = ktvList.filter(
          (x) => !shipperExtraIds.includes(x.value)
        );
        return (
          <tr key={id}>
            <td style={{ padding: "6px 10px" }}>
              <ShipperSelection
                key={id}
                value={ele.id}
                ktvList={ktvsExtra}
                changeShipper={(e) => this.handleChange("shipper", e, ele)}
                showHelp={false}
              />
            </td>
            <td align="center">
              <Radio
                color="primary"
                name="is-main-ktv"
                checked={ele.isMainKtv}
                onChange={(e) =>
                  this.handleChange("isMainKtv", e.target.checked, ele)
                }
              />
            </td>
            {!isMaxTrust && (
              <>
                <td align="center">
                  <Checkbox
                    color="primary"
                    checked={[1, 3].includes(ele.timekeeping_type)}
                    onChange={(e) =>
                      this.handleChange("isCheckin", e.target.checked, ele)
                    }
                  />
                </td>
                <td align="center">
                  <Checkbox
                    color="primary"
                    checked={[2, 3].includes(ele.timekeeping_type)}
                    onChange={(e) =>
                      this.handleChange("isCheckout", e.target.checked, ele)
                    }
                  />
                </td>
              </>
            )}

            {!isMaxTrust && (
              <td style={{ padding: "6px 10px" }} align="center">
                <DatePicker
                  className="customDatePicker createTransportDatePicker"
                  locale="vi-VN"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  value={new Date(ele.timekeeping_date)}
                  onChange={(e) =>
                    this.handleChange(
                      "date",
                      moment(e).format("YYYY-MM-DD"),
                      ele
                    )
                  }
                  clearIcon={null}
                />
              </td>
            )}
          </tr>
        );
      });
      return dataTemplate;
    }
  };
  render() {
    const { ktvList, shipperExtra } = this.props;
    const shipperExtraIds = shipperExtra.map((x) => x.id);
    const ktvsExtra = ktvList.filter((x) => !shipperExtraIds.includes(x.value));
    const isMaxTrust = isMaxTrustUser();

    return (
      <table id="extraKtvTable">
        <thead>
          <tr>
            <td
              rowSpan={2}
              align="center"
              style={{
                width: "60%",
              }}
            >
              KTV
            </td>
            <td rowSpan={2} align="center">
              KTV chính
            </td>
            {!isMaxTrust && (
              <>
                <td colSpan={2} align="center">
                  Chấm công
                </td>
                <td rowSpan={2} align="center">
                  Ngày chấm công
                </td>
              </>
            )}
          </tr>
          {!isMaxTrust && (
            <tr>
              <td align="center">Vào</td>
              <td align="center">Ra</td>
            </tr>
          )}
        </thead>
        <tbody>
          {this.renderExtraKtv(isMaxTrust)}
          <tr>
            <td
              style={{
                padding: "6px 10px",
                borderBottom: "1px solid #0000000d",
              }}
            >
              <ShipperSelection
                ktvList={ktvsExtra}
                changeShipper={(e) => this.handleChange("shipper", e, null)}
                closeOnSelect={false}
              />
            </td>
            <td
              colSpan={4}
              style={{
                borderBottom: "1px solid #0000000d",
              }}
            />
          </tr>
        </tbody>
      </table>
    );
  }
}

export default withStyles(style)(ShipperExtraSelection);
