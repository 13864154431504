import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  getBlBusLines,
  updateSuggestionCount,
  getCreateTransportSuggestions,
} from "../../../shared";
import { BlDirection } from "../../../../../models";
import { DialogEditToAddress } from "./customer_address";
import { fetchArea } from "../../../shared";
import moment from "moment";
import _ from "lodash";
import {
  validateUtils,
  measurementValidationMsg,
  measurementValidationRules,
  measurementValidationKtvRules,
  validateSuggestionList,
  toTimeKeepingFormat,
} from "../../../utils";
import { connect } from "react-redux";
import { getShipperList, getServiceListByShipMethod } from "../../actions";
import TransportByAll from "./TransportByAll";
import { ShipMethod, ShipType } from "../../../constants";
import CreateTransportSuggestions from "./CreateTransportSuggestions";
import { isMaxTrustUser } from "@utils";

const _getFormErrorMessage = (errors) => {
  const fields = Object.keys(errors);
  let msg = [];
  if (fields.indexOf("shipper") > -1) {
    msg.push("Bạn chưa chọn KTV giao hàng.");
  }
  if (fields.indexOf("to_shipper_id") > -1) {
    msg.push("Bạn chưa chọn KTV nhận hàng.");
  }
  if (fields.indexOf("to_hub_id") > -1) {
    msg.push("Bạn chưa chọn kho nhận.");
  }
  if (fields.indexOf("ship_method") > -1) {
    msg.push("Bạn chưa chọn nhà vận chuyển.");
  }
  if (fields.indexOf("shipper_match") > -1) {
    msg.push("Trùng KTV. Vui lòng chọn lại KTV Phụ.");
  }
  if (fields.indexOf("weight") > -1) {
    msg.push("Bạn chưa nhập khối lượng đơn hàng");
  }
  if (fields.indexOf("nv_phongvu") > -1) {
    msg.push("Bạn chưa nhập mã nhân viên giao hàng");
  }
  return msg;
};

const styles = (theme) => ({
  container: {
    width: "100%",
    marginBottom: "1rem",
  },
  actions: {
    margin: "6px 0 0 16px",
    display: "flex",
    justifyContent: "flex-start",
  },
  button: {
    marginLeft: 30,
    textTransform: "none",
  },
  left: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    paddingRight: 16,
  },
  right: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  text: {
    fontSize: 14,
  },
  suggestBar: {
    padding: 10,
    borderLeft: "1px solid #0000000f",
  },
});

class CreateTransport extends React.Component {
  constructor(props) {
    super(props);
    const { bl } = this.props;
    this.state = {
      shipType: "",
      shipMethod: "",
      toHub: {
        id: "",
        fullAddress: "",
      },
      toKTV: {
        id: "",
        fullAddress: "",
      },
      alter_address: null,
      shipperExtra: [],
      plRef: "",
      edt: null,
      note: "",
      weight: bl.package_info.weight || "1",
      height: bl.package_info.height || "1",
      width: bl.package_info.width || "1",
      length: bl.package_info.length || "1",
      inventory_count: bl.package_info.inventory_count || "1",
      service_id: "",
      allow_stock_view: true,
      vehicle_gas_cost: !this.props.bl.isWhTransfer,
      estimate_fee: "",
      bus_line: "",
      bus_line_list: [],
      error: {
        hasError: false,
        msg: "",
      },
      confirm: {
        hasOpen: false,
        msg: "",
      },
      currentKTV: {},
      openUpdateAddrDialog: false,
      creating: false,
      vertical: "top",
      horizontal: "center",
      openSnack: false,
      serviceList: [],
      suggestionsList: [],
      debtor: "",
      extra: [],
      suggestion_id: null,
    };
  }

  changeShipType = (shipType) => {
    this.setState(
      {
        shipType,
      },
      () => {
        if (shipType === ShipType.hub_ktv) {
          this.changeHubTo();
        } else if (shipType === ShipType.hub_hub) {
          if (this.props.bl.isWhTransfer) {
            this.changeHubTo();
          } else {
            this.changeKTVTo();
          }
        } else if (
          this.props.bl.isWhTransfer &&
          [ShipType.customer_customer, ShipType.hub_customer].includes(shipType)
        ) {
          this.changeHubTo({
            ...this.props.bl.toAddr,
            id: this.props.bl.toAddr.objId,
          });
        } else if (shipType === ShipType.hub_customer) {
          this.changeHubTo();
          this.changeKTVTo();
        } else if (shipType === ShipType.customer_hub) {
          this.changeHubTo();
        }
        this.setState({ plRef: null });
      }
    );
  };

  changeShipMethod = async (shipMethod) => {
    const { threePls, bl, getServiceListByShipMethod } = this.props;
    const { bus_line_list, estimate_fee } = this.state;
    const selected = threePls.filter((x) => x.code_name === shipMethod);
    const services = selected.length > 0 ? selected[0].services : [];
    const isMaxTrust = isMaxTrustUser();

    this.setState(
      {
        shipMethod,
        service_id:
          selected.length > 0 && selected[0].service
            ? selected[0].service
            : services.length > 0
            ? services[0].service_code
            : "",
        estimate_fee:
          selected.length > 0 ? selected[0].estimate_fee : estimate_fee,
        serviceList: [],
      },
      () => {
        this.setState({ plRef: null });
        if (bl.direction !== BlDirection.returning) {
          if (shipMethod === "ktv") {
            this.changeShipType(
              bl.isWhTransfer
                ? bl.holderType === "hub"
                  ? "hub_customer"
                  : "customer_customer"
                : "hub_customer"
            );
          } else if (["xe_tai", "xe_khach"].includes(shipMethod)) {
            if (bl.cod !== 0 && bl.direction !== "returning") {
              const type = isMaxTrust ? "hub_customer" : "hub_ktv";
              this.changeShipType(type);
            }
          }
        }
      }
    );
    if (shipMethod !== "ktv" && shipMethod) {
      const data = {
        weight: this.state.weight,
        height: this.state.height,
        width: this.state.width,
        length: this.state.length,
        ship_type: this.state.shipType,
        ship_method: shipMethod,
        hub_id:
          this.state.toHub && this.state.toHub.id ? this.state.toHub.id : "",
      };
      let serviceListResult = await getServiceListByShipMethod(bl.id, data);
      this.setState({ serviceList: serviceListResult.data });
    }
    if (shipMethod === "xe_khach" && bus_line_list.length > 0) {
      this.setState({ bus_line: bus_line_list[0].id });
    }
  };

  changeHubTo = (toHub) => {
    const { getShipperList, bl } = this.props;
    const { shipMethod } = this.state;
    if (bl.isWhTransfer && toHub && shipMethod === "ktv") {
      if (toHub.id) {
        getShipperList(bl.id, toHub.id);
      }
    }
    if (toHub) {
      this.setState({
        toHub,
      });
    } else {
      this.setState({
        toHub: {
          id: "",
          fullAddress: "",
        },
      });
    }
  };

  changeKTVTo = (toKTV, mustUpdateAddress) => {
    if (toKTV) {
      let ktv = this.props.allKtvList.find((value) => value.value === toKTV.id);
      this.setState({
        toKTV,
        currentKTV: ktv,
        alter_address: {
          label: toKTV.fullAddress,
          updateAddress: mustUpdateAddress,
          update: mustUpdateAddress,
        },
        openUpdateAddrDialog: Boolean(mustUpdateAddress),
      });
    } else {
      this.setState({
        toKTV: {
          id: "",
          fullAddress: "",
        },
      });
    }
  };

  changeValue = (value) => {
    this.setState(value);
  };

  toggleConfirmDialog = () => {
    this.setState({
      confirm: {
        hasOpen: !this.state.confirm.hasOpen,
        msg: "Bạn có muốn cập nhật thông tin KTV không ?",
      },
    });
  };

  closeConfirmDialog = () => {
    this.setState({
      toKTVAddress: {
        ...this.state.toKTVAddress,
        confirmUpdateInfo: false,
      },
      confirm: {
        hasOpen: false,
        msg: "",
      },
    });
  };
  toggleFormErrorDialog = (errors = []) =>
    this.setState({
      error: {
        hasError: !this.state.error.hasError,
        msg: errors,
      },
      creating: false,
    });

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnack: false });
  };

  handleUpdateAddr = (data) => {
    this.setState({
      alter_address: {
        ...this.state.alter_address,
        ...data,
      },
      openUpdateAddrDialog: false,
    });
  };

  createTransport = async () => {
    const {
      shipType,
      shipMethod,
      toHub,
      toKTV,
      plRef,
      edt,
      note,
      weight,
      height,
      width,
      length,
      inventory_count,
      vehicle_gas_cost,
      allow_stock_view,
      shipperExtra,
      areaCodeSelectValue,
      service_id,
      alter_address,
      estimate_fee,
      suggestion_id,
      is_debit,
      extra,
      bus_line,
    } = this.state;
    const { bl, threePls } = this.props;
    let params = {
      id: bl.id,
      shipType: shipType,
      shipMethod: shipMethod,
      toHub: toHub,
      toKTV: toKTV,
      shipper:
        shipperExtra.length > 0
          ? shipperExtra
              .filter((x) => x.isMainKtv)
              .map((x) => ({
                id: x.id,
                timekeeping_type: x.timekeeping_type,
                timekeeping_date: moment(x.timekeeping_date).format(
                  "YYYY-MM-DD"
                ),
                check_in_hour: toTimeKeepingFormat(x, "in"),
                check_out_hour: toTimeKeepingFormat(x, "out"),
              }))[0]
          : null,
      areaCodeSelectValue: areaCodeSelectValue,
      shipperExtra:
        shipperExtra.length > 0
          ? shipperExtra
              .filter((x) => !x.isMainKtv)
              .map((x) => ({
                id: x.id,
                timekeeping_type: x.timekeeping_type,
                timekeeping_date: moment(x.timekeeping_date).format(
                  "YYYY-MM-DD"
                ),
                check_in_hour: toTimeKeepingFormat(x, "in"),
                check_out_hour: toTimeKeepingFormat(x, "out"),
              }))
          : [],
      plRef: plRef,
      edt: edt,
      note: note,
      weight: weight,
      height: height,
      width: width,
      length: length,
      inventory_count: inventory_count,
      vehicle_gas_cost: vehicle_gas_cost,
      allow_stock_view: allow_stock_view,
      service_id: service_id,
      alter_address: alter_address,
      estimate_fee: estimate_fee,
      bus_line: bus_line,
      is_debit: is_debit,
      extra: extra,
      suggestion_id: suggestion_id,
    };
    if (shipType === "hub_ktv" && alter_address.updateAddress) {
      this.toggleFormErrorDialog(["Vui lòng cập nhật thông tin địa chỉ KTV"]);
      return;
    }
    // validate
    let customRules = {
      bus_line: {
        custom: function () {
          if (shipMethod === "xe_khach" && !bus_line) return true;
          return false;
        },
      },
      service_id: {
        custom: function () {
          let services = threePls.filter((x) => x.code_name === shipMethod);
          services = services.length > 0 ? services[0].services : [];
          if (shipMethod) {
            if (shipMethod === "ktv") {
              return false;
            } else {
              if (services.length > 0 && !service_id) {
                return true;
              }
            }
          }
          return false;
        },
      },
      extra: {
        custom: function () {
          if (shipMethod === "adidi") {
            if (extra[0].value === "installation") {
              if (
                extra[0].children[0].children &&
                extra[0].children[0].children.length > 0
              ) {
                return false;
              } else return true;
            }
          }
        },
      },
    };

    let err = [];
    if (params.shipMethod !== ShipMethod.ktv) {
      err = validateUtils(
        params,
        {
          ...measurementValidationRules,
          ...customRules,
        },
        measurementValidationMsg
      );
    } else {
      err = validateUtils(
        params,
        measurementValidationKtvRules,
        measurementValidationMsg,
        customRules
      );
    }
    if (err.length > 0) {
      this.toggleFormErrorDialog(err);
      return;
    } else {
      if (shipMethod === ShipMethod.nv_phongvu && (!plRef || !plRef.trim())) {
        this.toggleFormErrorDialog({ nv_phongvu: "required" });
        return;
      }
      this.setState({
        creating: true,
      });
      let response = await this.props.createTransport(params);
      this.setState({
        creating: false,
      });
      if (response && response.error) {
        this.toggleFormErrorDialog(response.msg);
      }
    }
  };

  async componentDidMount() {
    const { bl, isGroupBl, getShipperList } = this.props;
    const lastTrIdx = isGroupBl ? 0 : bl.transports.length;
    let data = {
      ship_type:
        bl.direction === BlDirection.returning
          ? "hub_hub"
          : bl.isWhTransfer
          ? bl.holderType === "hub"
            ? "hub_customer"
            : "customer_customer"
          : "hub_customer",
      weight:
        lastTrIdx > 0 ? bl.transports[lastTrIdx - 1].weight : this.state.weight,
      height:
        lastTrIdx > 0 ? bl.transports[lastTrIdx - 1].height : this.state.height,
      width:
        lastTrIdx > 0 ? bl.transports[lastTrIdx - 1].width : this.state.width,
      length:
        lastTrIdx > 0 ? bl.transports[lastTrIdx - 1].length : this.state.length,
      inventory_count:
        lastTrIdx > 0
          ? bl.transports[lastTrIdx - 1].inventory_count
          : this.state.inventory_count,
    };
    if (lastTrIdx > 0) {
      this.setState({
        weight: data.weight,
        height: data.height,
        width: data.width,
        length: data.length,
        inventory_count: data.inventory_count,
      });
    }

    /**
     * Set ship type mặc định cho transport
     *  - Trường hợp vận đơn chuyển kho:
     *    + hàng tại kho: customer_customer
     *    + hàng tại kho trung chuyển: hub_customer
     *  - Trường hợp hoàn hàng: mặc định là hub_hub
     *  - Others: hub_customer
     */
    if (bl.direction === BlDirection.returning) {
      data.ship_type = ShipType.hub_hub;
    } else if (bl.isWhTransfer) {
      data.ship_type =
        bl.holderType === "hub"
          ? ShipType.hub_customer
          : ShipType.customer_customer;
      this.changeHubTo({
        ...bl.toAddr,
        id: bl.toAddr.objId,
      });
      data.hub_id = bl.toAddr.objId;
    } else {
      getShipperList(bl.id);
      data.ship_type = ShipType.hub_customer;
    }
    this.setState({ shipType: data.ship_type });

    /* Lấy danh sách 3pls+chi phí */
    let result = await this.props.fetch3PlsList(this.props.bl.id, data);
    if (result && result.code === 200) {
      this.changeShipMethod(result.data.ThreePls[0].code_name);
    }

    /* Get bus lines */
    const response = await getBlBusLines(bl.id);
    if (response.status === 200) {
      const data = response.data.data.bus_lines;
      this.setState({ bus_line_list: data ? data : [] });
    }
    /* Lấy danh sách gợi ý lộ trình */
    getCreateTransportSuggestions(bl.id).then((response) => {
      if (response.status === 200) {
        const suggestions = response.data.data.suggestions;
        if (suggestions.length > 0) {
          this.setState({
            suggestionsList: suggestions,
          });
          //let defaultSelection = _.cloneDeep(suggestions[0]);
          //if (
          //  defaultSelection.ship_method === ShipMethod.ktv &&
          //  defaultSelection.ktv.length > 0
          //) {
          //  defaultSelection.ktv = defaultSelection.ktv[0];
          //} else {
          //  defaultSelection.ktv = null;
          //}
          //this.changeSuggestSelection(defaultSelection);
        }
      }
    });

    /* Chọn gán nợ */
    //TODO: check khi nào chọn
    if (["sale_order", "asia_order"].includes(bl.requestType.toLowerCase())) {
      this.setState({ is_debit: "true" });
    }
    /* Check trạng thái giao lại - set default ktv là ktv của last transports */
    if (
      !bl.must_transfer_debt &&
      bl.lastTransport &&
      bl.lastTransport.shipper
    ) {
      this.setState({
        shipperExtra: [
          {
            id: bl.lastTransport.shipper.id,
            timekeeping_date: new Date(),
            timekeeping_type: 0,
            value: bl.lastTransport.shipper.id,
            isMainKtv: true,
          },
        ],
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProp) {
    let follower = {
      id: nextProp.bl.follower ? nextProp.bl.follower.value : "",
      fullAddress: nextProp.bl.follower ? nextProp.bl.follower.fullAddress : "",
      isMainKtv: true,
      timekeeping_type: 0,
      timekeeping_date: new Date(),
    };
    if (
      JSON.stringify(nextProp.bl.follower) !==
      JSON.stringify(this.props.bl.follower)
    ) {
      this.setState({
        shipperExtra:
          nextProp.bl.follower && nextProp.bl.follower.id ? [follower] : [],
        toKTV: follower,
      });
    }
  }

  handleErrorMsg() {
    const { error } = this.state;
    let errMsg = [];
    if (Array.isArray(error.msg)) {
      error.msg.forEach((element, index) => {
        errMsg.push(<span key={`error-${index}`}>- {element}</span>);
        errMsg.push(<br key={`error-br-${index}`} />);
      });
    } else {
      errMsg = _getFormErrorMessage(error.msg).map((err, index) => (
        <span key={index}> - {err}</span>
      ));
    }
    return errMsg;
  }

  changeSuggestSelection = async (suggest) => {
    //gọi API update số lần chọn gợi ý
    updateSuggestionCount(suggest.id, "selected");
    //cập nhật gợi ý chọn cuối cùng
    this.setState({ suggestion_id: suggest.id });
    //fill form tạo lộ trình
    await this.changeShipMethod(suggest.ship_method);
    this.changeShipType(suggest.transport_type);
    if (suggest && suggest.ktv) {
      const ktv = this.props.shipperList.find((s) => s.value === suggest.ktv);
      this.setState({
        shipperExtra: [
          {
            ...ktv,
            id: suggest.ktv,
            timekeeping_date: new Date(),
            timekeeping_type: 0,
            value: suggest.ktv,
            isMainKtv: true,
          },
        ],
      });
    }
    if (suggest.hub_id) {
      const { hubs } = this.props;
      const selectedHub = hubs.find((x) => x.id === suggest.hub_id);
      await this.changeHubTo(selectedHub);
    }
  };

  render() {
    const {
      shipType,
      shipperExtra,
      creating,
      error,
      inventory_count,
      vehicle_gas_cost,
      openUpdateAddrDialog,
      suggestion_id,
      suggestionsList,
    } = this.state;
    const { bl, shipperList, classes } = this.props;
    let newList = _.cloneDeep(shipperList);
    const newProps = { ...this.props };
    delete newProps.classes;
    if (
      bl.follower &&
      newList.find((x) => x.value === bl.follower.value) === undefined
    ) {
      newList.push(bl.follower);
    }
    let validateSuggest = validateSuggestionList(suggestionsList);
    return (
      <Grid container className={classes.container}>
        <Grid item md={validateSuggest > 0 ? 8 : 10}>
          <TransportByAll
            {...this.state}
            {...newProps}
            /* Force lấy serviceList từ state sau khi change method. cần FE kiểm tra lại logic serviceList */
            serviceList={this.state.serviceList}
            ktvList={newList}
            changeShipType={this.changeShipType}
            changeHubTo={this.changeHubTo}
            changeKTVTo={this.changeKTVTo}
            changeEDT={this.changeEDT}
            changeNote={this.changeNote}
            changeareaCode={this.changeareaCode}
            changeValue={this.changeValue}
            inventoryCount={inventory_count}
            vehicleGasCost={vehicle_gas_cost}
            fetch3PlsList={this.props.fetch3PlsList}
            changeShipMethod={this.changeShipMethod}
            getServiceListByShipMethod={this.props.getServiceListByShipMethod}
          />
          <Grid container>
            <Grid item className={classes.actions} md={3} />
            <Grid item className={classes.actions}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.createTransport}
                disabled={creating}
              >
                {creating ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  <i className="material-icons">save</i>
                )}
                &nbsp;Lưu thông tin
              </Button>
            </Grid>
          </Grid>
          <Dialog
            open={error.hasError}
            title="Có lỗi xảy ra"
            closeDialog={this.toggleFormErrorDialog}
          >
            {this.handleErrorMsg()}
            <br />
            <span style={{ color: "#ff0000" }}>
              <span>*Lưu ý: </span>
              Các trường trên là bắt buộc. Vui lòng điền đầy đủ thông tin trước
              khi tạo lộ trình!
            </span>
          </Dialog>
          <Dialog
            open={this.state.confirm.hasOpen}
            title="Xác nhận"
            closeDialog={this.closeConfirmDialog}
            action={() => this.confirmUpdateInfo()}
          >
            {this.state.confirm.msg}
          </Dialog>
          {shipType === "hub_ktv" && (
            <DialogEditToAddress
              bl={bl}
              currentKTV={this.state.currentKTV}
              open={openUpdateAddrDialog}
              onClose={() =>
                this.setState({
                  openUpdateAddrDialog: false,
                })
              }
              onSave={this.handleUpdateAddr}
              fetchArea={this.props.fetchArea}
            />
          )}
        </Grid>
        {validateSuggest > 0 && shipperList.length > 0 && (
          <Grid item md={4} className={classes.suggestBar}>
            <CreateTransportSuggestions
              ktv={shipperExtra}
              bl={bl}
              value={suggestion_id}
              suggestions={suggestionsList}
              shipperList={shipperList}
              onSelect={(e) => this.changeSuggestSelection(e)}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  shipperList: state.admin.detail.shipperList,
  serviceList: state.admin.detail.serviceList,
  hubs: state.admin.shared.hubs,
});

const mapDispatchToProps = (dispatch) => ({
  getShipperList: (bl_id, hub_id) => dispatch(getShipperList(bl_id, hub_id)),
  fetchArea: (area, parent) => dispatch(fetchArea(area, parent)),
  getServiceListByShipMethod: (id, data) =>
    dispatch(getServiceListByShipMethod(id, data)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CreateTransport)
);
